import * as React from 'react'
import { Navigate, generatePath, useRoutes } from 'react-router-dom'
import { ErrorPageType } from '@constants/errorPageType'
import { routes } from '@constants/routes'
import '@styles/fonts.css'

const AdminIndex = React.lazy(() => import('@pages/Admin/Admin'))
const AdminOptions = React.lazy(() => import('@pages/Admin/Options'))
const AdminOptionForm = React.lazy(() => import('@pages/Admin/Options/ManageOption/ManageOption'))
const AdminLogin = React.lazy(() => import('@pages/Admin/Login/Login'))
const AdminLayoutTypes = React.lazy(() => import('@pages/Admin/LayoutTypes'))
const AdminLayoutTypeForm = React.lazy(() => import('@pages/Admin/LayoutTypes/LayoutTypeForm'))
const AdminLayoutTypeLightImages = React.lazy(
  () => import('@pages/Admin/LayoutTypes/LayoutTypeForm/LayoutTypeImages/LayoutTypeLightImages')
)
const AdminLayoutTypeDarkImages = React.lazy(
  () => import('@pages/Admin/LayoutTypes/LayoutTypeForm/LayoutTypeImages/LayoutTypeDarkImages')
)
const AdminLayoutTypeFreeOptions = React.lazy(
  () => import('@pages/Admin/LayoutTypes/LayoutTypeForm/LayoutTypeFreeOptions')
)
const AdminLayoutTypeOptions = React.lazy(() => import('@pages/Admin/LayoutTypes/LayoutTypeForm/LayoutTypeOptions'))
const AdminLayoutTypeStyles = React.lazy(() => import('@pages/Admin/LayoutTypes/LayoutTypeForm/LayoutTypeStyles'))
const AdminBuildings = React.lazy(() => import('@pages/Admin/Buildings'))
const AdminBuildingForm = React.lazy(() => import('@pages/Admin/Buildings/BuildingForm'))
const AdminHouses = React.lazy(() => import('@pages/Admin/Houses'))
const AdminHouseForm = React.lazy(() => import('@pages/Admin/Houses/HouseForm'))
const AdminHousePlanImages = React.lazy(() => import('@pages/Admin/Houses/HouseForm/HousePlanImages'))
const AdminHouseStyleExceptions = React.lazy(() => import('@pages/Admin/Houses/HouseForm/HouseStyleExceptions'))
const AdminHouseValueExceptions = React.lazy(() => import('@pages/Admin/Houses/HouseForm/HouseValueExceptions'))
const AdminBookings = React.lazy(() => import('@pages/Admin/Bookings'))
const Messages = React.lazy(() => import('@pages/Admin/Messages'))
const Settings = React.lazy(() => import('@pages/Admin/Settings'))
const ProjectSettings = React.lazy(() => import('@pages/Admin/Settings/ProjectSettings'))
const EnergyClasses = React.lazy(() => import('@pages/Admin/Settings/EnergyClasses'))
const FormsOfOwnership = React.lazy(() => import('@pages/Admin/Settings/FormsOfOwnership'))
const PropertyTypes = React.lazy(() => import('@pages/Admin/Settings/PropertyTypes'))

const CustomerIndex = React.lazy(() => import('@pages/Customer/Customer'))
const CustomerLanding = React.lazy(() => import('@pages/Customer/Landing'))
const CustomerBooking = React.lazy(() => import('@pages/Customer/Booking'))
const CustomerBookingSummary = React.lazy(() => import('@pages/Customer/BookingSummary'))

const ErrorPage = React.lazy(() => import('@pages/ErrorPage'))

const App: React.FC = () => {
  return useRoutes([
    {
      path: routes.Admin.Index,
      element: <AdminIndex />,
      children: [
        {
          index: true,
          element: <Navigate to={routes.Admin.Options} />
        },
        {
          path: routes.Admin.Options,
          element: <AdminOptions />
        },
        {
          path: routes.Admin.OptionNew,
          element: <AdminOptionForm />
        },
        {
          path: routes.Admin.OptionEdit,
          element: <AdminOptionForm />
        },
        {
          path: routes.Admin.LayoutTypes,
          element: <AdminLayoutTypes />
        },
        {
          path: routes.Admin.LayoutTypesNew,
          element: <AdminLayoutTypeForm />
        },
        {
          path: routes.Admin.LayoutTypesEdit,
          element: <AdminLayoutTypeForm />,
          children: [
            {
              path: routes.Admin.LayoutTypesEditLightImages,
              element: <AdminLayoutTypeLightImages />
            },
            {
              path: routes.Admin.LayoutTypesEditDarkImages,
              element: <AdminLayoutTypeDarkImages />
            },
            {
              path: routes.Admin.LayoutTypesEditFreeOptions,
              element: <AdminLayoutTypeFreeOptions />
            },
            {
              path: routes.Admin.LayoutTypesEditOptions,
              element: <AdminLayoutTypeOptions />
            },
            {
              path: routes.Admin.LayoutTypesEditStyles,
              element: <AdminLayoutTypeStyles />
            }
          ]
        },
        {
          path: routes.Admin.Buildings,
          element: <AdminBuildings />
        },
        {
          path: routes.Admin.BuildingsNew,
          element: <AdminBuildingForm />
        },
        {
          path: routes.Admin.BuildingsEdit,
          element: <AdminBuildingForm />
        },
        {
          path: routes.Admin.Houses,
          element: <AdminHouses />
        },
        {
          path: routes.Admin.HousesNew,
          element: <AdminHouseForm />
        },
        {
          path: routes.Admin.HousesEdit,
          element: <AdminHouseForm />,
          children: [
            {
              path: routes.Admin.HousesEditPlanImages,
              element: <AdminHousePlanImages />
            },
            {
              path: routes.Admin.HousesEditStyleExceptions,
              element: <AdminHouseStyleExceptions />
            },
            {
              path: routes.Admin.HousesEditValueExceptions,
              element: <AdminHouseValueExceptions />
            }
          ]
        },
        {
          path: routes.Admin.Bookings,
          element: <AdminBookings />
        },
        {
          path: routes.Admin.BookingsNew,
          element: <CustomerBooking />
        },
        {
          path: routes.Admin.BookingsEdit,
          element: <CustomerBookingSummary />
        },
        {
          path: routes.Admin.Messages,
          element: <Messages />
        },
        {
          path: routes.Admin.Settings,
          element: <Settings />,
          children: [
            {
              index: true,
              element: <Navigate to={routes.Admin.SettingsProjectSettings} />
            },
            {
              path: routes.Admin.SettingsProjectSettings,
              element: <ProjectSettings />
            },
            {
              path: routes.Admin.SettingsEnergyClasses,
              element: <EnergyClasses />
            },
            {
              path: routes.Admin.SettingsFormsOfOwnership,
              element: <FormsOfOwnership />
            },
            {
              path: routes.Admin.SettingsPropertyTypes,
              element: <PropertyTypes />
            }
          ]
        },
        {
          path: routes.Admin.Login,
          element: <AdminLogin />
        },
        {
          path: '*',
          element: <Navigate to={generatePath(routes.Error, { errorType: ErrorPageType.enum.PageNotFound })} />
        }
      ]
    },
    {
      path: routes.Customer.Index,
      element: <CustomerIndex />,
      children: [
        {
          index: true,
          element: <CustomerLanding />
        },
        {
          path: routes.Customer.Booking,
          element: <CustomerBooking />
        },
        {
          path: routes.Customer.BookingSummary,
          element: <CustomerBookingSummary />
        },
        {
          path: '*',
          element: <Navigate to={generatePath(routes.Error, { errorType: ErrorPageType.enum.PageNotFound })} />
        }
      ]
    },
    {
      path: routes.Customer.IndexView,
      element: <CustomerIndex />,
      children: [{ index: true, element: <CustomerLanding /> }]
    },
    {
      path: routes.Error,
      element: <ErrorPage />
    },
    {
      path: '*',
      element: <Navigate to={generatePath(routes.Error, { errorType: ErrorPageType.enum.PageNotFound })} />
    }
  ])
}

export default App
