// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const adminRoutes = () => {
  const baseRoute = '/admin'

  const optionsRoute = `${baseRoute}/options` as const

  const layoutTypesRoute = `${baseRoute}/layout-types` as const
  const layoutTypesIdRoute = `${layoutTypesRoute}/:layoutTypeId` as const

  const buildingsRoute = `${baseRoute}/buildings` as const

  const housesRoute = `${baseRoute}/houses` as const
  const housesIdRoute = `${housesRoute}/:houseId` as const

  const bookingsRoute = `${baseRoute}/bookings` as const

  const messagesRoute = `${baseRoute}/messages` as const

  const settingsRoute = `${baseRoute}/settings` as const

  return {
    Index: baseRoute,

    Options: optionsRoute,
    OptionNew: `${optionsRoute}/new`,
    OptionEdit: `${optionsRoute}/:optionId`,

    LayoutTypes: layoutTypesRoute,
    LayoutTypesNew: `${layoutTypesRoute}/new`,
    LayoutTypesEdit: layoutTypesIdRoute,
    LayoutTypesEditLightImages: `${layoutTypesIdRoute}/images/light`,
    LayoutTypesEditDarkImages: `${layoutTypesIdRoute}/images/dark`,
    LayoutTypesEditFreeOptions: `${layoutTypesIdRoute}/free-options`,
    LayoutTypesEditOptions: `${layoutTypesIdRoute}/options`,
    LayoutTypesEditStyles: `${layoutTypesIdRoute}/styles`,

    Buildings: buildingsRoute,
    BuildingsNew: `${buildingsRoute}/new`,
    BuildingsEdit: `${buildingsRoute}/:buildingId`,

    Houses: housesRoute,
    HousesNew: `${housesRoute}/new`,
    HousesEdit: housesIdRoute,
    HousesEditPlanImages: `${housesIdRoute}/images`,
    HousesEditStyleExceptions: `${housesIdRoute}/style-exceptions`,
    HousesEditValueExceptions: `${housesIdRoute}/value-exceptions`,

    Bookings: bookingsRoute,
    BookingsNew: `${bookingsRoute}/house/:houseId`,
    BookingsEdit: `${bookingsRoute}/:bookingId`,

    Messages: messagesRoute,

    Settings: settingsRoute,
    SettingsProjectSettings: `${settingsRoute}/project`,
    SettingsEnergyClasses: `${settingsRoute}/energy_classes`,
    SettingsFormsOfOwnership: `${settingsRoute}/forms_of_ownership`,
    SettingsPropertyTypes: `${settingsRoute}/property_types`,

    Login: `${baseRoute}/login`
  } as const
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const customerRoutes = () => {
  return {
    Index: '/',
    IndexView: '/:view',
    About: '/about',
    Location: '/location',
    Timeline: '/timeline',
    Houses: '/houses',
    Prices: '/prices',
    Contact: '/contact',
    ClientsDay: '/clients-day',
    Booking: '/booking/:houseId',
    BookingSummary: '/booking-summary/:bookingId'
  } as const
}

export const routes = {
  Customer: customerRoutes(),
  Admin: adminRoutes(),
  Error: '/error/:errorType'
}
