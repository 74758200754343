import { getWindowProperty } from '@digital-magic/react-common/lib/utils/window'
import { LanguageConfiguration } from '@digital-magic/react-common/lib/i18n'

export const apiBaseUrl = getWindowProperty('REACT_APP_API_BASE_URL') ?? ''
// TODO: Why isn't used? It should be heavily used by API
export const apiBaseUrlV1 = `${apiBaseUrl}/v1`

// eslint-disable-next-line no-console
console.log(`Initialized with params: APP_VERSION=${appVersion}, BASE_URL=${baseUrl}, API_BASE_URL=${apiBaseUrl}`)

export const dateFormat = 'yyyy-MM-dd'
export const timeFormat = 'HH:mm'
export const dateTimeShortFormat = `${dateFormat} ${timeFormat}`
export const dateTimeFullFormat = `yyyy-MM-dd ${timeFormat}`

export const displayDateFormat = 'dd-MM-yyyy'

export const priceCurrency = 'EUR'

const storagePrefix = 'wiimsi'

export const languageConfiguration: LanguageConfiguration = {
  translationNamespace: 'translations',
  loadPath: `/locales/{{lng}}/{{ns}}.json?v=${appVersion}`, // add app version so file is not cached
  version: appVersion,
  availableLanguages: ['en', 'et', 'ru'] as const,
  defaultLanguage: ['et', 'en', 'ru'] as const,
  storageKey: `${storagePrefix}_i18nextLng`,
  cacheStorageKeyPrefix: `${storagePrefix}_i18next_res_`,
  cacheExpirationMs: appDevMode ? 60 * 1000 : 7 * 24 * 60 * 60 * 1000
}

//export const authStateStorageKey = `${storagePrefix}_authState`
export const verificationCodeStorageKey = `${storagePrefix}_verificationCode`
export const personalizationStorageKey = `${storagePrefix}_personalization`

export const editBookingStorageKey = `${storagePrefix}_editBooking`

export const lightGalleryLicenceKey = '2E097D9B-C67946F0-80EBA307-59F2F2F6'
