/* eslint-disable i18next/no-literal-string */
import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { queryClient } from '@api/queryClient'
import { SnackbarProvider, closeSnackbar } from 'notistack'
import { CssBaseline } from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { GlobalStyles } from '@styles/GlobalStyles'
import theme from '@styles/theme/wiimsiTheme'
import App from './App'
import { i18n, initI18n } from './i18n'
import reportWebVitals from './reportWebVitals'

const LoadingComponent: React.FC = () => <div className="loading-component">Loading...</div>

void initI18n().then(() => {
  createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <BrowserRouter basename={baseUrl}>
            <StyledEngineProvider injectFirst>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <GlobalStyles />
                  <SnackbarProvider
                    maxSnack={3}
                    autoHideDuration={8000}
                    variant="error"
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    action={(snackbarId) => (
                      <a
                        href="#"
                        onClick={() => closeSnackbar(snackbarId)}
                        style={{ textDecoration: 'none', color: 'white', marginRight: '10px' }}
                      >
                        ✖
                      </a>
                    )}
                  >
                    <React.Suspense fallback={<LoadingComponent />}>
                      <App />
                    </React.Suspense>
                  </SnackbarProvider>
                </ThemeProvider>
              </LocalizationProvider>
            </StyledEngineProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </I18nextProvider>
    </React.StrictMode>
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
})
