import { css } from 'styled-components'
import alpha from 'color-alpha'

export const mixins = {
  flexCenter: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  flexCenterV: css`
    display: flex;
    align-items: center;
  `,
  flexCenterH: css`
    display: flex;
    justify-content: center;
  `,
  flexColumnSpaceBetween: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  opacity: alpha,
  engulfAbsolute: css`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  `,
  square: (size: string) => css`
    width: ${size};
    height: ${size};
  `,
  circle: (size: string) => css`
    width: ${size};
    height: ${size};
    border-radius: 50%;
  `
}
