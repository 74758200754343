import { createTheme } from '@mui/material/styles'
import { colors } from './colors'
import { mixins } from './mixins'

const theme = createTheme({
  mixins,
  colors,
  palette: {
    primary: {
      main: colors.brown
    },
    secondary: {
      main: colors.grey300
    }
  },
  bg: {
    main: '#fff'
  },
  text: {
    main: '#172B4D'
  },
  global: {
    typography: {
      bold: 700,
      medium: 500,
      regular: 400,
      size: {
        'size-12': '12px',
        'size-14': '14px',
        'size-16': '16px',
        'size-18': '18px',
        'size-22': '22px',
        'size-24': '24px',
        'size-20': '20px',
        'size-34': '34px',
        'size-30': '30px',
        'size-40': '40px',
        'size-46': '46px',
        'size-55': '55px',
        'size-60': '60px'
      }
    }
  },
  typography: {
    fontSize: 16,
    fontFamily: 'Montserrat, Roboto, sans-serif'
  }
})

export default theme
